import request from '@/utils/request'

export function getComputerKnowledgeById(data) {
  return request({
    url: `/api/ComputerKnowledges/GetComputerKnowledgeById/${data}`,
    method: 'get',
  })
}

export function getComputerKnowledges() {
  return request({
    url: '/api/ComputerKnowledges/GetComputerKnowledges',
    method: 'get',
  })
}

export function addComputerKnowledge(data) {
  return request({
    url: '/api/ComputerKnowledges/AddComputerKnowledge',
    method: 'post',
    data,
  })
}

export function updateComputerKnowledge(data) {
  return request({
    url: '/api/ComputerKnowledges/UpdateComputerKnowledge',
    method: 'put',
    data,
  })
}
export function deleteComputerKnowledge(data) {
  return request({
    url: `/api/ComputerKnowledges/DeleteComputerKnowledge?frComputerKnowledgesId=${data}`,
    method: 'delete',
  })
}
